







import Vue from 'vue'
import TopBar from './components/TopBar.vue'

export default Vue.extend({
  components:{
    TopBar,
  }
})

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

//pages
import Home from '../views/Home.vue'
import Recipe from '../views/Recipe.vue'
import Edit from '../views/Edit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/recipe/:id',
    name: 'Recipe',
    component: Recipe
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

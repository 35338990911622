import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {recipes,tags} from './firebase/init'


(async () => {
  Vue.config.productionTip = false
  
  //getting tags
  const tagsData = (await tags.get()).docs.map(d => ({...d.data(),id:d.id}))
  store.dispatch('setTags',tagsData)

  //getting recipes and getting tags
  const data = (await recipes.get()).docs.map(d => {
    const data = d.data()
    
    if(data.tags && data.tags.length != 0){
      data.tags = data.tags.map((id:string) => {
        //find tag
        const tag = tagsData.find(t => t.id == id)
        return tag
      })
    }

    return {...data,id:d.id}
  })

  store.dispatch('setRecipes',data)

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
  
})()
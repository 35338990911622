import firebase from 'firebase/app'
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD621kbXVvDozoQFRKnqJE5iuetuScfIMk",
  authDomain: "ninja-smoothies-boko.firebaseapp.com",
  databaseURL: "https://ninja-smoothies-boko.firebaseio.com",
  projectId: "ninja-smoothies-boko",
  storageBucket: "ninja-smoothies-boko.appspot.com",
  messagingSenderId: "268846639540",
  appId: "1:268846639540:web:807320b3876a3531c22ae1",
  measurementId: "G-T49CW4R4LB"
};


//to deploy run
// firebase deploy --only hosting:mom-cooking-v2

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore()
export const recipes = db.collection('recipes')
export const tags = db.collection('tags')
// export const recipes = db.collection('testing')

export default {
  db,
  recipes,
  tags,
}
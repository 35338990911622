

































import { RecipeType } from '@/store/types'
import Swal from 'sweetalert2'
import Vue from 'vue'
import {recipes} from '../firebase/init'
export default Vue.extend({
    data(){
        const data:{recipe:RecipeType} = {
            recipe:{ingredients:[],tags:[],title:"",source:"",recipe:"",description:""}
        }
        return data
    },
    created(){
        //finds the recipe from store
        const id = this.$route.params.id
        this.recipe = this.recipes.find((d:any) => d.id === id)
        if(!this.recipe){
            alert('בעיה: מתכון לא נמצא')
            this.$router.push('/')
        }
    },
    computed:{
        recipes(){
            return this.$store.state.recipe
        }
    },
    methods:{
        async deleteMe(){
            const {isConfirmed} = await Swal.fire({
                icon:"warning",
                title:"?האם אתה בטוח שאתה רוצה למחוק את מתכון זה",
                showDenyButton:true,
                denyButtonText:"ביטול",
                confirmButtonText:"מחק"
            })
            
            if(!isConfirmed) return;
            const id = this.$route.params.id
            await recipes.doc(id).delete()
            const index = this.recipes.findIndex((r:RecipeType) => r.id == id)

            if(index == -1){
                this.$router.push('/')
                return
            }

            const newRecipes:RecipeType[] = this.recipes
            newRecipes.splice(index,1)
            this.$store.dispatch("setRecipes",newRecipes)

            this.$router.push('/')
        }
    }
})

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    recipe:[],
    tags:[]
  },
  mutations: {
  },
  actions: {
    setRecipes:(context,payload) => {context.state.recipe = payload},
    setTags:(context,payload) => {context.state.tags = payload},
  },
  modules: {
  }
})


















































import { RecipeType, TagType } from '@/store/types'
import Swal from 'sweetalert2'
import Vue from 'vue'
import Tags from '../components/Tags.vue'
import {recipes} from '../firebase/init'
export default Vue.extend({
  data(){
    return{
      title:"",
      recipe:"",
      ingredients:[""],
      description:"",
      source:"",
      selectedTags:[""],

      //values
      tagsPopup:false,
      currentIngredient:"",
      id:""
    }
  },
  computed:{
    tags(){
      return this.$store.state.tags
    },
    recipesData(){
      return this.$store.state.recipe
    }
  },
  components:{
    Tags,
  },
  methods:{
    // fired on form submit
    async submit(){
      Swal.fire({
        didOpen(){
            Swal.showLoading()
        },
        backdrop:true,
        allowOutsideClick:false,
      })
      
      const recipe:RecipeType = {
        title:this.title,
        recipe:this.recipe,
        ingredients:this.ingredients,
        description:this.description,
        source:this.source,
        tags:this.selectedTags,
      }

      let id:string;
      if(this.id){
        id = await this.save(recipe)
      }
      else{
        id = await this.createNew(recipe)
      }

      Swal.close()
      this.$router.push(`/recipe/${id}`)
      
    },
    // creates a new document in the data base
    async createNew(recipe:RecipeType){
      const id = (await (await recipes.add(recipe)).get()).id
      recipe.id = id

      //updates list
      this.$store.dispatch('setRecipes',[...this.recipesData,recipe])
      return id
    },
    // update the current document
    async save(recipe:RecipeType){
      await recipes.doc(this.id).update(recipe)

      //gets tags
      recipe.tags = recipe.tags.map((t) => this.tags.find((lt:TagType) => lt.id == t))

      //update list
      const index = this.recipesData.findIndex((r:RecipeType) => r.id == this.id)
      const newData = this.recipesData
      newData[index] = {...recipe,id:this.id}
      this.$store.dispatch('setRecipes',newData)

      return this.id
    },
    // add an ingridient
    addIngredient(){
      this.ingredients.push(this.currentIngredient)
      this.currentIngredient = ""
    },
    // delete an ingridient
    deleteIngedient(index:number){
      this.ingredients.splice(index,1)
    }
  },
  created(){
    this.ingredients= []
    this.selectedTags = []
    if(!this.$route.query["id"]) return;

    //gets data
    this.id = this.$route.query["id"] as string
    const recpie = this.recipesData.find((r:RecipeType) => r.id == this.id) as RecipeType

    //sets data
    this.title = recpie.title
    this.ingredients = recpie.ingredients
    this.recipe = recpie.recipe

    if(recpie.tags && recpie.tags.length != 0){
      this.selectedTags = recpie.tags.map((tag:any) => tag.id)
    }

    if(recpie.description){
      this.description = recpie.description
    }

    if(recpie.source){
      this.source = recpie.source
    }
  },
})






























import { RecipeType, TagType } from '@/store/types';
import Swal from 'sweetalert2';
import Vue from 'vue';
import Tags from '../components/Tags.vue'
export default Vue.extend({
  name: 'Home',
  data(){
    return{
      tagsPopup:false,
      searchFilter:"",
      filters:[""],
    }
  },
  computed:{
    recipes(){
      return this.$store.state.recipe.filter((r:RecipeType) => {
        //filters recipes
        //title filter
        if(!r.title.includes(this.searchFilter)) return false;

        //tag filter
        if(this.filters.length === 0) return true;
        if(!r.tags || r.tags.length == 0) return false;

        for(let i = 0; i < this.filters.length; i++){
          const filter = this.filters[i]
          const index = r.tags.findIndex((tag:any) => tag.id == filter)
          if(index != -1){
            return true
          }
        }

        return false
      })
    },
    tags(){
      return this.$store.state.tags
    }
  },
  components:{
    Tags,
  },
  created(){
    this.filters = []
  },
  methods:{
    async openFilerBox(){
      const checkForFilter = (id:string) => {
        if(this.filters.findIndex((f:string) => f == id) != -1){
          return "checked"
        }
        return ""
      }

      const createInputs = () => {
        let out = ''
        this.tags.forEach((tag:TagType) => {
          out += `
          <div class="list-item">
          <label for="${tag.id}">${tag.tag}</label>
          <input type="checkbox" name="${tag.id}" id="filter-item-${tag.id}" ${checkForFilter(tag.id)} class="swal2-input">
          </div>
          `
        })

        return out
      }

      const {value:filters,isDenied:clean} = await Swal.fire({
        title: 'טאגים לסינון',
        html:createInputs(),
        confirmButtonText:"סיום",
        showDenyButton:true,
        denyButtonText:"נקה",
        preConfirm: () => {
          const out:string[] = []
          this.tags.forEach((tag:TagType) => {
            if((document.getElementById(`filter-item-${tag.id}`) as HTMLInputElement).checked){
              out.push(tag.id)
            }
          })
          return out
        }
      })

      if(clean){
        this.filters = []
      }
      else{
        this.filters = filters!;
        this.$forceUpdate()
      }
    }
  }
});

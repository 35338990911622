





















import Vue from 'vue'
import {tags} from '../firebase/init'
import Swal from 'sweetalert2'
import {TagType} from '../store/types'
export default Vue.extend({
    name:"Tags",
    data(){
        return{
            newTag:"",
        }
    },
    methods:{
        async addTag(){
            if(!this.newTag) return;

            Swal.fire({
                didOpen(){
                    Swal.showLoading()
                },
                backdrop:true,
                allowOutsideClick:false,
            })

            //sending new tag and getting the id
            const id = (await (await tags.add({tag:this.newTag})).get()).id

            this.$store.dispatch('setTags',[...this.tags,{tag:this.newTag,id}])
            this.newTag = ""
            Swal.close()
        },
        async deleteTag(id:string){
            Swal.fire({
                didOpen(){
                    Swal.showLoading()
                },
                backdrop:true,
                allowOutsideClick:false,
            })

            //deletes tag
            await tags.doc(id).delete()

            //removes from list
            const newTags = this.tags
            const index = this.tags.findIndex((t:TagType) => t.id == id)
            newTags.splice(index,1)
            this.$store.dispatch('setTags',newTags)

            Swal.close()
        }
    },
    computed:{
        tags(){
            return this.$store.state.tags
        }
    }
})
